import type { FunctionComponent, SVGProps } from 'react';
import type { ButtonHTMLAttributes } from 'react';
import { ringClass } from '~/styles/constants';

export type ButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'danger';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  appearance?: ButtonStyle;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  className?: string;
}

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  appearance: style = 'primary',
  icon: Icon,
  className,
  ...props
}) => {
  const classByStyle: Record<ButtonStyle, string> = {
    primary: 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-400',
    secondary: 'bg-slate-200 text-slate-700 hover:bg-slate-300 focus:bg-slate-100',
    tertiary: 'bg-slate-100 text-slate-700 hover:bg-slate-200 focus:bg-slate-50',
    danger: 'bg-red-500 text-white hover:bg-red-600 focus:bg-red-400',
  };

  const commonClass = `text-center font-medium rounded flex justify-center items-center gap-2 py-2 px-6 ${ringClass}`;

  return (
    <button className={`${classByStyle[style]} ${commonClass} ${className}`} {...props}>
      {Icon && <Icon className='w-5 h-5 flex-none' />}
      {children}
    </button>
  );
};
